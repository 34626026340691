export default [
  {
    path: '/cars',
    name: 'cars',
    component: () => import('@/views/cars/Car.vue'),
    meta: {
      pageTitle: 'Автомобили',
      auth: ['superuser', 'manager', 'accountant'],
    },
    props: {
      table: 'default',
    },
  },
  {
    path: '/cars-new',
    name: 'cars-new',
    component: () => import('@/views/cars/Car.vue'),
    meta: {
      pageTitle: 'Автомобили',
      auth: ['superuser', 'manager', 'accountant'],
    },
    props: {
      table: 'new',
    },
  },
  {
    path: '/cars/plans',
    name: 'cars-plan',
    component: () => import('@/views/cars/CarPlan.vue'),
    meta: {
      pageTitle: 'График смен',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/cars/plans/report',
    name: 'cars-plan-report',
    component: () => import('@/views/cars/CarPlanReport.vue'),
    meta: {
      pageTitle: 'График смен',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/cars/acts',
    name: 'cars-act',
    component: () => import('@/views/cars/CarAct.vue'),
    meta: {
      pageTitle: 'Акты приема передачи',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/cars/sheets',
    name: 'cars-sheets',
    component: () => import('@/views/cars/CarSheet.vue'),
    meta: {
      pageTitle: 'Путевые',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/cars/:id',
    name: 'cars-view',
    component: () => import('@/views/cars/CarDetail.vue'),
    meta: {
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
]
