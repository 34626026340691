import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    outKey(state) {
      return state.items.filter(item => item.category === 'out_key')
    },
    operation(state) {
      return state.items.filter(item => item.category === 'operation')
    },
    carComplect(state) {
      return state.items.filter(item => item.category === 'car_complect')
    },
    tags(state) {
      return state.items.filter(item => item.category === 'tags')
    },
    other(state) {
      return state.items.filter(item => item.category === 'other')
    },
  },
  mutations: {
    SET_ITEMS(state, payload) {
      state.items = payload
    },
  },
  actions: {
    FETCH({ commit }) {
      return new Promise(resolve => {
        axios.get('directories').then(response => {
          commit('SET_ITEMS', response.data.data)
        })
          .catch()
          .finally(() => resolve())
      })
    },
  },
}
