export default [
  {
    path: '/mailing',
    name: 'mailing-list',
    component: () => import('@/views/mailing/MailingList.vue'),
    meta: {
      pageTitle: 'Рассылки',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/mailing/:id',
    name: 'mailing-item-list',
    component: () => import('@/views/mailing/MailingItemList.vue'),
    meta: {
      pageTitle: 'Список рассылки',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
]
