// eslint-disable-next-line import/no-cycle
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

import drivers from './drivers'
import users from './users'
import money from './money'
import directories from './directories'
import cars from './cars'
import reports from './reports'
import mailing from './mailing'
import lk from './lk'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home/Index.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        auth: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    ...drivers,
    ...users,
    ...money,
    ...directories,
    ...cars,
    ...reports,
    ...mailing,
    ...lk,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

function appIsReady() {
  return new Promise(resolve => {
    if (!store.state.isReady) {
      store.dispatch('INIT_APP').finally(resolve)
    } else {
      resolve()
    }
  })
}

router.beforeEach((to, from, next) => {
  appIsReady()
    .then(() => {
      if (to.meta.auth && store.state.user.access_token === null) {
        if (from.name !== 'login') next({ name: 'login' })
      } else if (Array.isArray(to.meta.auth) && to.meta.auth.indexOf(store.state.user.role) === -1) {
        next({ name: 'error-404' })
      } else {
        next()
      }
    })
})
export default router
