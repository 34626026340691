import moment from 'moment'
import Vue from 'vue'

export const dateFormat = function (value, date = true, time = true) {
  const formatArr = []
  if (date) {
    formatArr.push('DD.MM.YY')
  }

  if (time) {
    formatArr.push('hh:mm')
  }

  if (value) {
    return moment(String(value)).format(formatArr.join(' '))
  }
  return ''
}

export const titleCase = function (value) {
  return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
}

export const contractNumber = function (driver) {
  if (!driver.contract_serial || !driver.contract_number) {
    return '-'
  }
  const num = `${driver.contract_number}`.padStart(4, '0')
  return `${driver.contract_serial}${num}`
}

export const displayDriver = function (driver) {
  return [driver.last_name, driver.first_name, driver.middle_name].join(' ')
}

Vue.filter('dateFormat', dateFormat)
Vue.filter('titleCase', titleCase)
Vue.filter('contractNumber', contractNumber)
Vue.filter('displayDriver', displayDriver)
