export default [
  {
    path: '/drivers',
    name: 'driver-list',
    component: () => import('@/views/drivers/DriverList.vue'),
    meta: {
      pageTitle: 'Водители',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/drivers/:id',
    name: 'driver-view',
    component: () => import('../views/drivers/DriverView.vue'),
    meta: {
      pageTitle() {
        return `Водитель #${this.$route.params.id}`
      },
      backTo: { name: 'user-list' },
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
]
