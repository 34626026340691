export default [
  {
    path: '/lk/plan',
    name: 'lk-plan',
    component: () => import('@/views/lk/LkPlan.vue'),
    meta: {
      pageTitle: 'График',
      auth: ['user'],
    },
  },
]
