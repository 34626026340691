export default [
  {
    path: '/money/operations',
    name: 'operation-list',
    component: () => import('@/views/operations/OperationList.vue'),
    meta: {
      pageTitle: 'Операции',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/money/operations/view/:id',
    name: 'operation-view',
    component: () => import('@/views/operations/OperationView.vue'),
    meta: {
      pageTitle() {
        return `Операция #${this.$route.params.id}`
      },
      backTo: { name: 'operation-list' },
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/money/operations/packages',
    name: 'package-list',
    component: () => import('@/views/operations/PackageList.vue'),
    meta: {
      pageTitle: 'Пакеты платежных поручений',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
]
