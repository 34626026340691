export default [
  {
    path: '/reports/yandex/trips',
    name: 'report-yandex-trips',
    component: () => import('@/views/reports/YandexTrips.vue'),
    meta: {
      pageTitle: 'Поездки Yandex',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
]
