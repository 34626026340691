export default [
  {
    path: '/settings/directories',
    name: 'directories-list',
    component: () => import('../views/directories/DirectoriesList.vue'),
    meta: {
      pageTitle: 'Справочники',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
]
