<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component
      :is="layout"
      v-if="$store.state.isReady"
    >
      <router-view />
    </component>
  </div>
</template>

<script setup>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
import { watch } from 'vue'

const {
  skin,
  // eslint-disable-next-line no-unused-vars
  skinClasses,
} = useAppConfig()

// If skin is dark when initialized => Add class to body
if (skin.value === 'dark') document.body.classList.add('dark-layout')

store.commit('app/UPDATE_LOGO_IMAGE', $themeConfig.app.appLogoImage)
// Set Window Width in store
store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
const { width } = useWindowSize()

watch(width, val => {
  store.commit('app/UPDATE_WINDOW_WIDTH', val)
})
watch(() => store.state.isReady, val => {
  console.log(val)
  if (val) {
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'none'
    }
  }
})
</script>

<script>

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,

  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  async beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement)
        .value
        .trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement)
        .value
        .slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 95px!important;
}
.main-menu .navbar-header .navbar-brand .brand-logo{
  margin-top: -10px;
}
</style>
