import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import user from './user'
import directories from './directories'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    directories,
  },
  state: {
    isReady: false,
  },
  mutations: {
    APP_READY(state) {
      state.isReady = true
      const appLoading = document.getElementById('loading-bg')
      if (appLoading) {
        appLoading.style.display = 'none'
      }
    },
  },
  actions: {
    INIT_APP({
      dispatch,
      commit,
    }) {
      return Promise.all([
        dispatch('user/SESSION_INIT'),
        dispatch('user/FETCH'),
        dispatch('directories/FETCH'),
        commit('APP_READY'),
      ])
    },
  },
  strict: process.env.DEV,
})
