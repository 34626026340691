export default [
  {
    path: '/settings/users',
    name: 'user-list',
    component: () => import('../views/users/UserList.vue'),
    meta: {
      pageTitle: 'Пользователи',
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
  {
    path: '/settings/users/:id',
    name: 'user-view',
    component: () => import('../views/users/UserDetail.vue'),
    meta: {
      pageTitle() {
        return `Пользователь #${this.$route.params.id}`
      },
      backTo: { name: 'user-list' },
      auth: ['superuser', 'manager', 'accountant'],
    },
  },
]
