import Vue from 'vue'
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import router from '@/router'

const axiosIns = axios.create({
  baseURL: '//api.partner-ug.com',
  timeout: 15000,
  headers: { Accept: 'application/json' },
})

axiosIns.interceptors.request.use(
  config => {
    if (store.state.user.access_token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${store.state.user.access_token}`,
        },
      }
    }
    return config
  },
)

axiosIns.interceptors.response.use(
  response => {
    if (response.data instanceof Blob && response.headers['content-type'] === 'application/pdf') {
      const filename = response.headers['content-disposition']
        .split(';')
        .find(n => n.includes('filename='))
        .replace('filename=', '')
        .trim()
      window.open(
        URL.createObjectURL(response.data), filename,
      )
    }
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.commit('user/CLEAR')
      if (router.currentRoute.name !== 'login') {
        router.push({ name: 'login' })
      }
    }

    if (error.response.status === 500) {
      Vue.$toast.error('Не известная ошибка!')
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns
Vue.prototype.$axios = axiosIns

export default axiosIns
